import React from 'react'
import Layout from '../components/layout'

const ImprintPage = (props) => (
  <Layout {...props}>
    <section styleName='' className='sm-gutters'>
      <div className='container'>
        <h2>Imprint</h2>
        According to § 5 TMG:
        <br />
        <br />Mobius Labs GmbH 
        <br />Rudi-Dutschke-Straße 23
        <br />10969 Berlin
        <br />
        <br />E-mail: hey@mobius.ml
        <br />Tel: + 49 176 264 254 06
        <br />
        <br />Managing Director: Dr. Appu Shaji
        <br />Register court: Amtsgericht Charlottenburg
        <br />Registration number: HRB 195708 B
        <br />VAT ID : DE 317 967 312
        <br />
        <br />Responsible according to § 55 RStV:
        <br />Kai Krämer
        <br />
        <br />Privacy officer:
        <br />Kai Krämer
        <br />datenschutz@mobius.ml
      </div>
    </section>
  </Layout>
)

export default ImprintPage
